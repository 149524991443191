angular.module('userMaintenanceDwtModule').factory(
  'DwtIssuerUser',
  [
    'userService',
    function(userService) {
      function DwtIssuerUser(userData, groupId, userId, loggedInId,
        accType, prodType) {
        if (undefined !== userData) {
          this.accountType = userData.accountType;
          this.title = userData.title;
          this.firstName = userData.firstName;
          this.lastName = userData.lastName;
          this.workPhone = userData.officePhoneNo;
          this.emailAddress = userData.email;
          this.userType = 'IS';
          this.userGroupId = groupId;
          this.prodType = prodType;
          this.defaultLanguage = 'EN';
          this.status = userData.status;
          this.userId = userData.userId;
          this.loggedInUserId = loggedInId;
          this.blockGroupEmail = userData.groupMail;
          if (userData.admin === 'Y') {
            this.isAdmin = 'Y';
          } else if (userData.admin === 'R') {
            this.isAdmin = 'R';
          } else {
            this.isAdmin = 'N';
          }
        } else {
          this.accountType = accType;
          this.title = '';
          this.firstName = '';
          this.lastName = '';
          this.personalPhone = '';
          this.workPhone = '';
          this.emailAddress = '';
          this.userType = 'IS';
          this.userGroupId = groupId;
          this.prodType = prodType;
          this.defaultLanguage = 'EN';
          this.status = 'P';
          this.userId = userId;
          this.loggedInUserId = loggedInId;
          this.blockGroupEmail = 'N';
          this.isAdmin = 'N';
        }
      }

      DwtIssuerUser.prototype.updateUser = function(successCallback,
        errorCallback) {
        userService.editUser(this.userId, this, 'secureToken',
          successCallback, errorCallback);
      };

      DwtIssuerUser.prototype.createUser = function(successCallback,
        errorCallback) {
        userService.createNewUser(this, 'secureToken').then(
          successCallback, errorCallback);
      };

      return DwtIssuerUser;
    }]);