angular.module('userMaintenanceDwtModule').factory(
  'DwtShareclassUser',
  [
    'userService',
    '$filter',
    function(userService) {
      function DwtShareclassUser(userData, groupId, userId,
        shareclassId) {
        var self = this;
        if (undefined !== userData) {
          self.isin = userData.isin;
          self.prefix = userData.prefix;
          self.shareclassName = userData.shareclassName;
          self.shareclassId = shareclassId;
          self.langCode = 'EN';
          self.issuerId = groupId;
          self.userId = userId;
          self.productCode = 'DWT';
          self.showWarningMsg = false;
          self.showWarning();
          self.isOverwrite = 'N';
        } else {
          self.isin = '';
          self.prefix = '';
          self.shareclassName = '';
          self.shareclassId = shareclassId;
          self.langCode = 'EN';
          self.issuerId = groupId;
          self.userId = userId;
          self.productCode = 'DWT';
          self.showWarningMsg = false;
          self.isOverwrite = 'N';
        }
      }

      DwtShareclassUser.prototype.updateUser = function(
        successCallback, errorCallback, proceedFlag) {
        if ('Y' === proceedFlag) {
          this.isOverwrite = 'Y';
        }
        userService.editShareclass(this.issuerId, this, 'secureToken',
          successCallback, errorCallback);
      };

      DwtShareclassUser.prototype.createUser = function(
        successCallback, errorCallback, proceedFlag) {
        if ('Y' === proceedFlag) {
          this.isOverwrite = 'Y';
        }
        userService.createNewShareclass(this, 'secureToken').then(
          successCallback, errorCallback);
      };
      DwtShareclassUser.prototype.showWarning = function() {
        var pattForLetter = /[a-zA-Z]/;
        var pattForAlphaNum = /[a-zA-Z0-9]/;
        var pattForNumber = /[0-9]/;
        if (12 !== this.isin.length) {
          this.showWarningMsg = true;
        } else {
          var first = this.isin.substring(0, 2);
          var second = this.isin.substring(2, 11);
          var third = this.isin.substring(this.isin.length - 1);
          if (!(pattForLetter.test(first)
                          && pattForAlphaNum.test(second) && pattForNumber
            .test(third))) {
            this.showWarningMsg = true;
          } else {
            this.showWarningMsg = false;
          }
        }
      };
      return DwtShareclassUser;
    }]);