angular.module('userMaintenanceDwtModule').factory('DwtShareholderUser', [
  'userGroupService',
  'userService',
  '$filter',
  function (userGroupService, userService, $filter) {
    var self;
    function DwtShareholderUser(
      userData,
      groupId,
      userId,
      shareholderId,
      shareclassId,
      accType,
      eventId
    ) {
      self = this;
      if (undefined !== userData) {
        self.accountType = userData.peShareHolderVO.shareHolderType;
        self.title = userData.peShareHolderVO.title;
        self.initials = userData.peShareHolderVO.initials;
        self.name = userData.peShareHolderVO.shareHolderName;
        self.lei = userData.peShareHolderVO.legalEntityIdentifier;
        self.contactPerson = userData.peShareHolderVO.contactPerson;
        self.workPhone = userData.peShareHolderVO.phoneNumber;
        self.streetAddress = userData.peShareHolderVO.address;
        self.city = userData.peShareHolderVO.city;
        self.country = userData.peShareHolderVO.country;
        self.emailAddress = userData.peShareHolderVO.emailAddress;
        self.userType = 'SH';
        self.userGroupId = groupId;
        self.eventId = eventId;
        self.prodType = 'DWT';
        self.defaultLanguage = 'EN';
        self.status = 'P';
        self.userId = userId;
        self.intermediaryList = userData.peIntermediaryVO;
        self.showMoreIntermiaryLink = false;
        self.showNotListedIntermiary = false;
        self.showIntermiaryLink = true;
        self.isin = userData.shareClassVO[0].isin;
        self.shareclassId = userData.shareClassVO[0].shareclassId;
        self.shareholderId = shareholderId;
        if (
          null !== userData.peIntermediaryVO &&
          userData.peIntermediaryVO.length > 0
        ) {
          self.showMoreIntermiaryLink = true;
          self.showIntermiaryLink = false;
        } else {
          self.showMoreIntermiaryLink = false;
          self.showIntermiaryLink = true;
        }
        angular.forEach(self.intermediaryList, function (value, key) {
          if ('0' === value.intermediaryId) {
            self.intermediaryList[key].showNewIntermediaryDtls = true;
          } else {
            self.intermediaryList[key].showNewIntermediaryDtls = false;
          }
        });
      } else {
        self.accountType = accType;
        self.title = 'Mr.';
        self.initials = '';
        self.name = '';
        self.lei = '';
        self.contactPerson = '';
        self.workPhone = '';
        self.streetAddress = '';
        self.city = '';
        self.country = '';
        self.emailAddress = '';
        self.userType = 'SH';
        self.eventId = eventId;
        self.userGroupId = groupId;
        self.prodType = 'DWT';
        self.defaultLanguage = 'EN';
        self.status = 'P';
        self.userId = userId;
        self.intermediaryList = [];
        self.showMoreIntermiaryLink = false;
        self.showIntermiaryLink = true;
        self.isin = '';
        self.shareclassId = null;
        userService
          .getDefaultIsin(groupId, shareclassId, 'DWT', 'EN', userId)
          .query()
          .$promise.then(
            function (data) {
              self.isinList = data;
              if (self.isinList.length > 1) {
                self.showIsinLabel = false;
              } else {
                self.showIsinLabel = true;
                self.shareclassId = self.isinList[0].shareclassId;
              }
            },
            function () {}
          );
        self.shareholderId = shareholderId;
      }
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (countries) {
            self.countries = countries.countries;
            self.selectedCountry = $filter('filter')(
              self.countries,
              {
                countryName: self.country,
              },
              true
            );
          },
          function () {}
        );
      userGroupService
        .getIntermediaryList('DWT')
        .query()
        .$promise.then(
          function (intermediaries) {
            self.intermediaries = intermediaries;
            self.intermediaries.push({
              intermediaryName: 'Not listed',
              intermediaryId: '0',
              addFlag: 'Y',
            });
          },
          function () {}
        );
    }
    DwtShareholderUser.prototype.getIntermediaryList = function () {
      userGroupService
        .getIntermediaryList('DWT')
        .query()
        .$promise.then(
          function (intermediaries) {
            self.intermediaries = intermediaries;
          },
          function () {}
        );
    };

    DwtShareholderUser.prototype.updateUser = function (
      successCallback,
      errorCallback
    ) {
      var documentFormData = new FormData();
      documentFormData.append('eventId', this.eventId);
      documentFormData.append('issuerId', this.userGroupId);
      documentFormData.append('shareholderId', this.shareholderId);
      documentFormData.append('userId', this.userId);
      documentFormData.append(
        'peShareHolderVO.shareHolderType',
        this.accountType
      );
      if ('LGL' === this.accountType) {
        documentFormData.append('peShareHolderVO.title', '');
        documentFormData.append('peShareHolderVO.initials', '');
      } else {
        documentFormData.append('peShareHolderVO.title', this.title);
        documentFormData.append('peShareHolderVO.initials', this.initials);
      }
      documentFormData.append('peShareHolderVO.shareHolderName', this.name);
      if (undefined !== this.lei) {
        documentFormData.append(
          'peShareHolderVO.legalEntityIdentifier',
          this.lei
        );
      }
      if (undefined !== this.contactPerson) {
        documentFormData.append(
          'peShareHolderVO.contactPerson',
          this.contactPerson
        );
      }
      if (undefined !== this.workPhone) {
        documentFormData.append('peShareHolderVO.phoneNumber', this.workPhone);
      }
      if (undefined !== this.streetAddress) {
        documentFormData.append('peShareHolderVO.address', this.streetAddress);
      }
      if (undefined !== this.city) {
        documentFormData.append('peShareHolderVO.city', this.city);
      }
      if (undefined !== this.emailAddress) {
        documentFormData.append(
          'peShareHolderVO.emailAddress',
          this.emailAddress
        );
      }
      documentFormData.append(
        'peShareHolderVO.country',
        this.selectedCountry[0].isoCountryCode
      );

      documentFormData.append('shareClassVO[0].isin', this.isin);
      documentFormData.append(
        'shareClassVO[0].shareclassId',
        this.shareclassId
      );
      documentFormData.append('shareClassVO[0].issuerId', this.userGroupId);
      documentFormData.append('shareClassVO[0].userId', this.userId);

      angular.forEach(this.intermediaryList, function (value, key) {
        documentFormData.append(
          'peIntermediaryVO[' + key + '].intermediaryId',
          value.intermediaryId
        );
        if (value.intermediaryName) {
          documentFormData.append(
            'peIntermediaryVO[' + key + '].intermediaryName',
            value.intermediaryName
          );
        }
        if (value.intermediaryEmailAddress) {
          documentFormData.append(
            'peIntermediaryVO[' + key + '].intermediaryEmailAddress',
            value.intermediaryEmailAddress
          );
        }
        documentFormData.append(
          'peIntermediaryVO[' + key + '].notify',
          value.notify
        );
        if (
          undefined !== value.proofofEntitlement &&
          null !== value.proofofEntitlement &&
          '' !== value.proofofEntitlement
        ) {
          documentFormData.append(
            'peIntermediaryVO[' + key + '].proofofEntitlement',
            value.proofofEntitlement
          );
          documentFormData.append(
            'peIntermediaryVO[' + key + '].fileName',
            value.proofofEntitlement.name
          );
        }
      });
      userService
        .editShareholder(this.shareholderId, documentFormData)
        .then(successCallback, errorCallback);
    };

    DwtShareholderUser.prototype.createUser = function (
      successCallback,
      errorCallback
    ) {
      var documentFormData = new FormData();
      documentFormData.append('eventId', this.eventId);
      documentFormData.append('issuerId', this.userGroupId);
      documentFormData.append('shareholderId', this.shareholderId);
      documentFormData.append('userId', this.userId);
      documentFormData.append(
        'peShareHolderVO.shareHolderType',
        this.accountType
      );
      documentFormData.append('peShareHolderVO.title', this.title);
      documentFormData.append('peShareHolderVO.initials', this.initials);
      documentFormData.append(
        'peShareHolderVO.legalEntityIdentifier',
        this.lei
      );
      documentFormData.append('peShareHolderVO.shareHolderName', this.name);
      documentFormData.append(
        'peShareHolderVO.contactPerson',
        this.contactPerson
      );
      documentFormData.append('peShareHolderVO.phoneNumber', this.workPhone);
      documentFormData.append('peShareHolderVO.address', this.streetAddress);
      documentFormData.append('peShareHolderVO.city', this.city);
      documentFormData.append(
        'peShareHolderVO.country',
        this.selectedCountry[0].isoCountryCode
      );

      if (
        undefined !== this.emailAddress &&
        null !== this.emailAddress &&
        '' !== this.emailAddress
      ) {
        documentFormData.append(
          'peShareHolderVO.emailAddress',
          this.emailAddress
        );
      }

      documentFormData.append('shareClassVO[0].isin', this.isin);
      documentFormData.append(
        'shareClassVO[0].shareclassId',
        this.shareclassId
      );
      documentFormData.append('shareClassVO[0].issuerId', this.userGroupId);
      documentFormData.append('shareClassVO[0].userId', this.userId);

      angular.forEach(this.intermediaryList, function (value, key) {
        documentFormData.append(
          'peIntermediaryVO[' + key + '].intermediaryId',
          value.intermediaryId
        );
        if (value.intermediaryName) {
          documentFormData.append(
            'peIntermediaryVO[' + key + '].intermediaryName',
            value.intermediaryName
          );
        }
        if (value.intermediaryEmailAddress) {
          documentFormData.append(
            'peIntermediaryVO[' + key + '].intermediaryEmailAddress',
            value.intermediaryEmailAddress
          );
        }
        documentFormData.append(
          'peIntermediaryVO[' + key + '].notify',
          value.notify
        );
        if (
          undefined !== value.proofofEntitlement &&
          null !== value.proofofEntitlement &&
          '' !== value.proofofEntitlement
        ) {
          documentFormData.append(
            'peIntermediaryVO[' + key + '].proofofEntitlement',
            value.proofofEntitlement
          );
          documentFormData.append(
            'peIntermediaryVO[' + key + '].fileName',
            value.proofofEntitlement.name
          );
        }
      });
      userService
        .createNewShareholder(documentFormData, 'secureToken')
        .then(successCallback, errorCallback);
    };

    DwtShareholderUser.prototype.addMoreIntermediary = function () {
      this.showMoreIntermiaryLink = true;
      this.showIntermiaryLink = false;
      var newIntermediary = {
        intermediaryId: '',
        notify: 'N',
        proofofEntitlement: '',
      };
      this.intermediaryList.push(newIntermediary);
    };
    DwtShareholderUser.prototype.removeIntermediary = function (index) {
      this.intermediaryList.splice(index, 1);
      if (this.intermediaryList.length === 0) {
        this.showMoreIntermiaryLink = false;
        this.showIntermiaryLink = true;
      }
    };

    DwtShareholderUser.prototype.callOnIntermediary = function (
      intermediaryId,
      shIntId,
      index
    ) {
      if ('0' === intermediaryId) {
        this.intermediaryList[index].showNewIntermediaryDtls = true;
        if (intermediaryId && shIntId) {
          this.intermediaryList[index].intermediaryName = null;
          this.intermediaryList[index].intermediaryEmailAddress = null;
        }
      } else {
        this.intermediaryList[index].showNewIntermediaryDtls = false;
      }
    };

    DwtShareholderUser.prototype.addNotListedIntermediary = function (
      intermediary
    ) {
      if (intermediary.intermediaryId === '00') {
        this.showNotListedIntermiary = true;
      }
    };
    return DwtShareholderUser;
  },
]);
