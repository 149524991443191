angular
  .module('dwtApproveRejectExtensionModule')
  .factory('DwtApproveRejectExtn', [
    'ViewDwtExtensionReqService',
    '$filter',
    '$stateParams',
    '$sessionStorage',
    function (ViewDwtExtensionReqService) {
      function DwtApproveRejectExtn(
        approveRejectData,
        approveRejectFlag,
        oneAllFlag,
        userType
      ) {
        this.extReqId = approveRejectData.extReqId;
        this.intermediaryId = approveRejectData.intermediaryId;
        this.prodCode = 'DWT';
        this.name = approveRejectData.name;
        this.issuerName = approveRejectData.issuerName;
        this.date = approveRejectData.date;
        this.extendDate = approveRejectData.endDateString;
        this.eventType = approveRejectData.dwtType;
        this.eventId = approveRejectData.eventId;
        this.userType = userType;
        if (approveRejectFlag === 'Accept') {
          this.status = approveRejectFlag;
          this.flag = null;
        } else {
          this.status = approveRejectFlag;
          if (oneAllFlag === 'ONE') {
            this.flag = 'ONE';
          } else {
            this.flag = 'ALL';
          }
        }
      }
      DwtApproveRejectExtn.prototype.dwtApproveReject = function (
        successCallback,
        errorCallback
      ) {
        ViewDwtExtensionReqService.dwtApproveReject(this).then(
          successCallback,
          errorCallback
        );
      };
      return DwtApproveRejectExtn;
    },
  ]);
