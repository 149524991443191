angular.module('dwtModule').factory('viewDwtDetailModal', [
  'ViewDwtDetailService',
  function () {
    function viewDwtDetailModal(data) {
      this.peShareholderDetails = [];
      this.eventComments = [];
      // this.claimType = data.dwtPaymentSummary.claimType;
      this.issuerDetails = data.issuerVO;
      this.directDebitDetails = data.directDebitVO;
      this.securityClassDetails = data.securityClassVO;
      this.dividendDetails = data.dividendVO;
      this.scheduleDetails = data.dwtScheduleVO;
      this.peShareholderDetails = data.peShareholderVOList;
      this.eventComments = data.dwtComments;
    }
    return viewDwtDetailModal;
  },
]);
