angular.module('userMaintenanceDwtModule').factory('DwtAgentGroup', [
  'userGroupService',
  'fileUploadSvc',
  '$filter',
  function (userGroupService, fileUploadSvc, $filter) {
    function DwtAgentGroup(userData, userId) {
      var self = this;
      self.groupname = userData.username;
      self.username = userData.userGroupVO.agentDisplayName;
      self.personalPhone = userData.personalPhone;
      self.emailAddress = userData.emailAddress;
      self.address = userData.userGroupVO.address;
      self.postalCode = userData.userGroupVO.postalCode;
      self.city = userData.city;
      self.country = userData.country;
      self.userGroupVO = userData.userGroupVO;
      self.userId = userId;
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (countries) {
            self.countries = countries.countries;
            self.selectedCountry = $filter('filter')(
              self.countries,
              {
                countryName: self.country,
              },
              true
            );
          },
          function () {}
        );
      self.chamberOfCommerce = userData.userGroupVO.chamberOfCommerce;
      self.vatNo = userData.userGroupVO.vatNo;
    }

    DwtAgentGroup.prototype.updateGroup = function (
      file,
      uploadUrl,
      userId,
      successCallback,
      errorCallback,
      productCode
    ) {
      var documentFormData = new FormData();
      documentFormData.append('groupName', this.groupname);
      documentFormData.append('agentDisplayName', this.username);
      documentFormData.append('userVO.workPhone', this.personalPhone);
      documentFormData.append('userId', userId);
      documentFormData.append('groupId', 1);
      documentFormData.append('groupEmail', this.emailAddress);
      documentFormData.append('city', this.city);
      documentFormData.append(
        'country',
        this.selectedCountry[0].isoCountryCode
      );
      documentFormData.append('productCode', productCode);
      documentFormData.append('address', this.address);
      documentFormData.append('postalCode', this.postalCode);
      documentFormData.append('chamberOfCommerce', this.chamberOfCommerce);
      documentFormData.append('vatNo', this.vatNo);
      if (file !== null && file !== undefined) {
        documentFormData.append('logo', file);
      }
      fileUploadSvc
        .uploadFileToUrl(file, uploadUrl, documentFormData)
        .then(successCallback, errorCallback);
    };
    return DwtAgentGroup;
  },
]);
