angular.module('dwtCreateExtensionModule').factory('DwtCreateExtn', [
  'CreateDwtExtensionReqService',
  '$filter',
  '$stateParams',
  '$timeout',
  function (CreateDwtExtensionReqService, $filter, $stateParams, $timeout) {
    function DwtCreateExtn(createExtnDataParms, createExtnDataStorage) {
      this.returnObj = {};
      this.gmDateTime = $stateParams.taSubmissionDate;
      this.reason = '';
      if (createExtnDataParms.paymentDate != null) {
        this.intermediaryDeadlineDate = new Date(
          createExtnDataParms.paymentDate
        );
        this.intermediaryDeadlineDate = $filter('date')(
          this.intermediaryDeadlineDate,
          'yyyy-MM-dd HH:mm:ss'
        );
      } else {
        this.intermediaryDeadlineDate = '';
      }

      this.endDate = '';
      this.endDateString = '';
      this.dwtType = '';
      this.sendDisabled = false;
      this.example = { value: new Date(1970, 0, 1, 0, 0, 0) };
      this.extensionRequestId = createExtnDataParms.extensionRequestId;
      if (
        undefined !== createExtnDataParms &&
        undefined !== createExtnDataStorage
      ) {
        this.extReqId = createExtnDataParms.eventId;
        this.userId = createExtnDataStorage.userId;
        this.name = createExtnDataParms.eventName;
        this.issuerName = createExtnDataParms.issuerName;
        this.intermediaryName = createExtnDataParms.intermediaryName;
        this.paymentDate = createExtnDataParms.paymentDate;
        this.issuerId = createExtnDataParms.issuerId;
        if (
          createExtnDataParms.extensionRequestId &&
          createExtnDataParms.reason !== null
        ) {
          this.dwtType = createExtnDataParms.dwtType;
          if (this.dwtType) {
            this.endDateModified = createExtnDataParms.endDate;
            var endDateFormat = new Date(createExtnDataParms.endDateString);
            this.example = {
              value: new Date(
                1970,
                0,
                1,
                endDateFormat.getHours(),
                endDateFormat.getMinutes(),
                0
              ),
            };
          }
          this.reason = createExtnDataParms.reason;
        }
      } else {
        this.extReqId = '';
        this.userId = '';
        this.name = '';
        this.issuerName = '';
        this.intermediaryName = '';
        this.paymentDate = '';
        this.issuerId = '';
      }
    }
    DwtCreateExtn.prototype.createDwtExtensionRequest = function (
      successCallback,
      errorCallback
    ) {
      this.sendDisabled = true;
      var timeData = new Date(this.example.value);
      var dateTime = new Date(this.endDateModified);
      dateTime.setMinutes(timeData.getMinutes());
      dateTime.setHours(timeData.getHours());
      this.endDate = dateTime.getTime();
      if (this.endDateModified != null || this.endDateModified != '') {
        this.tempEndDate = new Date(this.endDateModified);
        this.tempEndDate.setMinutes(timeData.getMinutes());
        this.tempEndDate.setHours(timeData.getHours());
        this.endDateString = $filter('date')(
          this.tempEndDate,
          'yyyy-MM-dd HH:mm:ss'
        );
      }
      this.getGmDateTime = new Date(this.gmDateTime);
      this.getGmDateTime = this.getGmDateTime.getTime();
      if (this.endDate > this.getGmDateTime) {
        this.createExtnErrRes =
          'Extend deadline till date must not be greater than Event end date.';
      } else {
        this.createExtnErrRes = '';
        if (this.extensionRequestId == null) {
          // create
          CreateDwtExtensionReqService.createDwtExtensionRequest(
            this,
            'secureToken'
          ).then(successCallback, errorCallback);
        } else {
          // update
          CreateDwtExtensionReqService.updateDwtExtensionRequest(
            this.extensionRequestId,
            this,
            'secureToken'
          ).then(successCallback, errorCallback);
        }
      }
      $timeout(
        function () {
          this.createExtnErrRes = '';
          this.sendDisabled = false;
        }.bind(this),
        4000
      );
    };

    return DwtCreateExtn;
  },
]);
