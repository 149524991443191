angular.module('userMaintenanceDwtModule').factory('DwtIntermediaryGroup', [
  'userGroupService',
  'fileUploadSvc',
  '$filter',
  function (userGroupService, fileUploadSvc, $filter) {
    function DwtIntermediaryGroup(userData, userId) {
      var self = this;
      if (undefined === userData) {
        self.username = '';
        self.emailAddress = '';
        self.city = '';
        self.country = '';
        self.bankIdentifierCode = '';
        self.ibanNumber = '';
        self.paymentRefNo = '';
        self.startDate = '';
        self.endDate = '';
        self.userId = userId;
        self.comments = '';
        self.phoneNo = '';
        self.validateIBAN = 'Y';
      } else {
        self.username = userData.groupName;
        self.emailAddress = userData.groupEmail;
        self.city = userData.city;
        self.country = userData.country;
        self.bankIdentifierCode = userData.bankIdentifierCode;
        self.ibanNumber = userData.ibanNumber;
        if (
          undefined !== userData.paymentRefNo &&
          null !== userData.paymentRefNo
        ) {
          self.paymentRefNo = userData.paymentRefNo;
        } else {
          self.paymentRefNo = '';
        }
        self.startDate = '';
        self.endDate = '';
        self.userId = userId;
        if (undefined !== userData.comments && null !== userData.comments) {
          self.comments = userData.comments;
        } else {
          self.comments = '';
        }
        if (
          undefined !== userData.paymentRefNo &&
          null !== userData.paymentRefNo
        ) {
          self.phoneNo = userData.phoneNo;
        } else {
          self.phoneNo = '';
        }
        self.validateIBAN = 'Y';
        self.logoBaseStr = userData.logoBaseStr;
      }
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (response) {
            self.countries = response.countries;
            self.selectedCountry = $filter('filter')(
              self.countries,
              {
                countryName: self.country,
              },
              true
            );
          },
          function () {}
        );
    }
    DwtIntermediaryGroup.prototype.addGroup = function (
      logoFile,
      file,
      uploadUrl,
      userId,
      groupName,
      prodType,
      successCallback,
      errorCallback
    ) {
      var documentFormData = new FormData();
      documentFormData.append('groupName', groupName);
      documentFormData.append('userId', userId);
      documentFormData.append('groupEmail', this.emailAddress);
      documentFormData.append('city', this.city);
      documentFormData.append('bankIdentifierCode', this.bankIdentifierCode);
      documentFormData.append(
        'country',
        this.selectedCountry[0].isoCountryCode
      );
      documentFormData.append('ibanNumber', this.ibanNumber);
      documentFormData.append('comments', this.comments);
      documentFormData.append('phoneNo', this.phoneNo);
      documentFormData.append('validateIBAN', this.validateIBAN);
      if (undefined !== logoFile && null !== logoFile) {
        documentFormData.append('logo', logoFile);
      }
      if ('' !== this.paymentRefNo) {
        documentFormData.append('paymentRefNo', this.paymentRefNo);
      }
      documentFormData.append('productCode', prodType);
      if (undefined !== file && null !== file) {
        documentFormData.append('intermediaryVO.agreementForm', file);
        if (file.name !== undefined) {
          documentFormData.append(
            'intermediaryVO.agreementFormName',
            file.name
          );
        }
        var startDate = new Date(this.startDate);
        var endDate = new Date(this.endDate);
        documentFormData.append(
          'intermediaryVO.effectiveEndDate',
          $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
        );
        documentFormData.append(
          'intermediaryVO.effectiveStartDate',
          $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
        );
      }

      fileUploadSvc
        .uploadFileToUrl(file, uploadUrl, documentFormData)
        .then(successCallback, errorCallback);
    };
    DwtIntermediaryGroup.prototype.updateGroup = function (
      logoFile,
      file,
      uploadUrl,
      userId,
      prodType,
      intermediaryId,
      successCallback,
      errorCallback
    ) {
      var documentFormData = new FormData();
      documentFormData.append('groupId', intermediaryId);
      documentFormData.append('groupName', this.username);
      documentFormData.append('userId', userId);
      documentFormData.append('groupEmail', this.emailAddress);
      documentFormData.append('city', this.city);
      documentFormData.append('bankIdentifierCode', this.bankIdentifierCode);
      documentFormData.append(
        'country',
        this.selectedCountry[0].isoCountryCode
      );
      documentFormData.append('ibanNumber', this.ibanNumber);
      if (null != this.comments) {
        documentFormData.append('comments', this.comments);
      }
      documentFormData.append('phoneNo', this.phoneNo);
      documentFormData.append('validateIBAN', this.validateIBAN);
      if (undefined !== logoFile && null !== logoFile) {
        documentFormData.append('logo', logoFile);
      }
      if ('' !== this.paymentRefNo) {
        documentFormData.append('paymentRefNo', this.paymentRefNo);
      }
      documentFormData.append('productCode', prodType);
      if (file !== undefined && null !== file) {
        documentFormData.append('intermediaryVO.agreementForm', file);
        if (file.name !== undefined) {
          documentFormData.append(
            'intermediaryVO.agreementFormName',
            file.name
          );
        }
        var startDate = new Date(this.startDate);
        var endDate = new Date(this.endDate);
        documentFormData.append(
          'intermediaryVO.effectiveEndDate',
          $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
        );
        documentFormData.append(
          'intermediaryVO.effectiveStartDate',
          $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
        );
      }
      fileUploadSvc
        .uploadFileToUrl(file, uploadUrl, documentFormData)
        .then(successCallback, errorCallback);
    };
    return DwtIntermediaryGroup;
  },
]);
