/**
 * @ngdoc controller
 * @name dwtModule
 * @description This is a modal object creation used for create and edit tax reclaim events of dwt module.
 * @requires $sessionStorage to store/pass data in session
 * @requires dwtEvent function to load the modal object with service data for create and edit dwt events
 * @requires createEditDwtService
 */
angular.module('dwtModule').factory('searchShareholderModal', [
  '$sessionStorage',
  'searchShareholderService',
  function ($sessionStorage, searchShareholderService) {
    function searchShareholderModal() {
      this.shareHolderName = '';
      this.shareHolderSecurityAccNo = '';
      this.city = '';
      this.country = null;
      this.tin = '';
      this.intermediaryId = null;
      this.issuerId = null;
      this.searchShareholderWithoutValid6166 = '';
    }

    searchShareholderModal.prototype.searchShareholder = function (
      successCallback,
      errorCallback
    ) {
      var request = {
        dwtShareholderVO: {
          shareHolderName: this.shareHolderName,
          shareholderSecurityAccNo: this.shareHolderSecurityAccNo,
          city: this.city,
          country: this.country,
          tin: this.tin,
          searchShareholderWithoutValid6166: this
            .searchShareholderWithoutValid6166,
        },
      };
      searchShareholderService
        .searchShareholder(request, 'secureToken')
        .then(successCallback, errorCallback);
    };

    return searchShareholderModal;
  },
]);
