angular.module('userMaintenanceDwtModule').factory(
  'DwtIntermediaryUser',
  [
    'userService',
    function(userService) {
      function DwtIntermediaryUser(userData, groupId, userId,
        loggedInId, accType, prodType) {
        if (undefined !== userData) {
          this.accountType = userData.accountType;
          this.title = userData.title;
          this.firstName = userData.firstName;
          this.lastName = userData.lastName;
          this.workPhone = userData.officePhoneNo;
          this.emailAddress = userData.email;
          this.userType = 'IN';
          this.userGroupId = groupId;
          this.prodType = prodType;
          this.defaultLanguage = 'EN';
          this.status = userData.status;
          this.userId = userData.userId;
          this.loggedInUserId = loggedInId;
          this.blockGroupEmail = userData.groupMail;
          this.isAdmin = 'N';
        } else {
          this.accountType = accType;
          this.title = '';
          this.firstName = '';
          this.lastName = '';
          this.personalPhone = '';
          this.workPhone = '';
          this.emailAddress = '';
          this.userType = 'IN';
          this.userGroupId = groupId;
          this.prodType = prodType;
          this.defaultLanguage = 'EN';
          this.status = 'P';
          this.userId = userId;
          this.loggedInUserId = loggedInId;
          this.blockGroupEmail = 'N';
          this.isAdmin = 'N';
        }
      }

      DwtIntermediaryUser.prototype.updateUser = function(
        successCallback, errorCallback) {
        userService.editUser(this.userId, this, 'secureToken',
          successCallback, errorCallback);
      };

      DwtIntermediaryUser.prototype.createUser = function(
        successCallback, errorCallback) {
        userService.createNewUser(this, 'secureToken').then(
          successCallback, errorCallback);
      };

      return DwtIntermediaryUser;
    }]);