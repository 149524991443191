angular.module('dwtCreateQRFClaimModule').factory('DwtCreateQRF', [
  'CreateDwtQRFReqService',
  'ViewDwtDetailService',
  '$sessionStorage',
  function (CreateDwtQRFReqService, ViewDwtDetailService, $sessionStorage) {
    function DwtCreateQRF(createQRFDataParms, intermediaryId) {
      this.returnObj = {};
      this.peClaimId = null;
      this.claimId = createQRFDataParms.qrfClaimId;
      this.userType = $sessionStorage.usertype;
      this.eventId = createQRFDataParms.eventId;
      this.shareholderId = createQRFDataParms.shareholderId;
      this.intermediaryId = intermediaryId;
      this.loginUserId = createQRFDataParms.loginUserId;
      this.isin = createQRFDataParms.isin;
      this.dwtPerSecurity = createQRFDataParms.dwtPerSecurity;
      this.shareclassId = createQRFDataParms.shareClassId;
      this.rsinNumber = '';
      this.tin = '';
      this.securityAccountNo = '';
      this.firstName = '';
      this.lastName = '';
      this.postalCode = '';
      this.email = '';
      this.city = '';
      this.country = 'NLD';
      this.phoneNumber = '';
      this.position = 0;
      this.claimAmount = '';
      this.address = '';
      this.paymentReference = '';
      this.fundManager = '';
      this.shareClassName = '';
      this.claimType = 'QRF';
      this.searchclickedflag = false;
      this.onloadFlag = false;
    }
    DwtCreateQRF.prototype.createDwtQRFRequest = function (
      successCallback,
      errorCallback
    ) {
      this.searchclickedflag = false;
      this.onloadFlag = false;
      if (this.claimId) {
        this.claimAmount = this.dwtPerSecurity * this.position;
        CreateDwtQRFReqService.editDWTQRFRequest(
          this,
          this.eventId,
          this.claimId
        ).then(successCallback, errorCallback);
      } else {
        this.claimAmount = this.dwtPerSecurity * this.position;
        CreateDwtQRFReqService.createDwtQRFRequest(this).then(
          successCallback,
          errorCallback
        );
      }
    };
    DwtCreateQRF.prototype.searchSans = function (
      successCallback,
      errorCallback
    ) {
      this.searchclickedflag = true;
      this.onloadFlag = false;
      CreateDwtQRFReqService.searchSans(this).then(
        successCallback,
        errorCallback
      );
    };

    DwtCreateQRF.prototype.viewDWTQRFRequest = function (
      successCallback,
      errorCallback
    ) {
      this.searchclickedflag = false;
      this.onloadFlag = true;
      ViewDwtDetailService.getDwtClaimsDetailsView(
        this.claimId,
        this.userType,
        this.eventId
      ).then(successCallback, errorCallback);
    };
    return DwtCreateQRF;
  },
]);
