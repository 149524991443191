angular.module('dwtModule').factory('viewDwtClaimDetailModal', [
  'ViewDwtDetailService',
  function (ViewDwtDetailService) {
    function viewDwtClaimDetailModal(data, userType) {
      this.claimOverviewList = [];
      this.peclaims = [];
      this.qrfclaims = [];
      this.claimOverviewList = data.claimOverviewList;
      this.peclaims = data.peclaims;
      this.qrfclaims = data.qrfclaims;
      this.isin = data.isin;
      this.currency = data.currency;
      this.peEnabled = data.peenabled;
      if (userType !== 'AG') {
        this.qrfAmount = data.qrfAmount;
        this.peAmount = data.peAmount;
        this.totalAmount = data.totalAmount;
      }
    }
    return viewDwtClaimDetailModal;
  },
]);
