angular.module('userMaintenanceDwtModule').factory('DwtIssuerGroup', [
  'userGroupService',
  'fileUploadSvc',
  '$filter',
  function (userGroupService, fileUploadSvc, $filter) {
    function DwtIssuerGroup(userData, userId, prodType) {
      var self = this;
      if (undefined === userData) {
        self.userName = '';
        self.emailAddress = '';
        self.city = '';
        self.country = '';
        self.postalcode = '';
        self.address = '';
        self.ibanQrf = '';
        self.ibanPe = '';
        self.rsinFiscal = '';
        self.startDate = '';
        self.endDate = '';
        self.userId = userId;
        self.comments = '';
        self.prodType = prodType;
      } else {
        self.userName = userData.groupName;
        self.emailAddress = userData.groupEmail;
        self.city = userData.city;
        self.country = userData.country;
        self.postalcode = userData.postalCode;
        self.address = userData.address;
        self.ibanQrf = userData.ibanQRF;
        self.ibanPe = userData.ibanPE;
        self.rsinFiscal = userData.taxDeclarationNo;
        self.startDate = null;
        self.endDate = null;
        self.userId = userId;
        if (undefined !== userData.comments && null !== userData.comments) {
          self.comments = userData.comments;
        } else {
          self.comments = '';
        }
        self.prodType = prodType;
        self.logoBaseStr = userData.logoBaseStr;
      }
      userGroupService
        .getCountryList('EN')
        .query()
        .$promise.then(
          function (response) {
            self.countries = response.countries;
            self.selectedCountry = $filter('filter')(
              self.countries,
              {
                countryName: self.country,
              },
              true
            );
          },
          function (error) {}
        );
    }

    DwtIssuerGroup.prototype.addGroup = function (
      logoFile,
      agreeFile,
      uploadUrl,
      userId,
      groupName,
      successCallback,
      errorCallback
    ) {
      var documentFormData = new FormData();
      documentFormData.append('groupName', groupName);
      documentFormData.append('userId', userId);
      documentFormData.append('groupEmail', this.emailAddress);
      documentFormData.append('city', this.city);
      documentFormData.append(
        'country',
        this.selectedCountry[0].isoCountryCode
      );
      documentFormData.append('productCode', this.prodType);
      documentFormData.append('comments', this.comments);
      if (undefined !== logoFile && null !== logoFile) {
        documentFormData.append('logo', logoFile);
      }
      if (null != this.postalcode && '' !== this.postalcode) {
        documentFormData.append('postalCode', this.postalcode);
      }
      if (null != this.address && '' !== this.address) {
        documentFormData.append('address', this.address);
      }
      if (null != this.ibanPe && '' !== this.ibanPe) {
        documentFormData.append('ibanPE', this.ibanPe);
      }
      if (null != this.ibanQrf && '' !== this.ibanQrf) {
        documentFormData.append('ibanQRF', this.ibanQrf);
      }
      documentFormData.append('taxDeclarationNo', this.rsinFiscal);
      if (undefined !== agreeFile && null !== agreeFile) {
        documentFormData.append('issuerAgreementVO.agreementForm', agreeFile);
        if (agreeFile.name !== undefined) {
          documentFormData.append(
            'issuerAgreementVO.agreementFormName',
            agreeFile.name
          );
        }
        var startDate = new Date(this.startDate);
        var endDate = new Date(this.endDate);
        documentFormData.append(
          'issuerAgreementVO.effectiveEndDate',
          $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
        );
        documentFormData.append(
          'issuerAgreementVO.effectiveStartDate',
          $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
        );
      }
      fileUploadSvc
        .uploadFileToUrl(null, uploadUrl, documentFormData)
        .then(successCallback, errorCallback);
    };
    DwtIssuerGroup.prototype.updateGroup = function (
      logoFile,
      agreeFile,
      uploadUrl,
      userId,
      groupId,
      successCallback,
      errorCallback
    ) {
      var documentFormData = new FormData();
      documentFormData.append('groupId', groupId);
      documentFormData.append('groupName', this.userName);
      documentFormData.append('userId', userId);
      documentFormData.append('groupEmail', this.emailAddress);
      documentFormData.append('city', this.city);
      documentFormData.append(
        'country',
        this.selectedCountry[0].isoCountryCode
      );
      documentFormData.append('productCode', this.prodType);
      if (null != this.comments) {
        documentFormData.append('comments', this.comments);
      }
      if (undefined !== logoFile && null !== logoFile) {
        documentFormData.append('logo', logoFile);
      }
      if (null != this.postalcode && '' !== this.postalcode) {
        documentFormData.append('postalCode', this.postalcode);
      }
      if (null != this.address && '' !== this.address) {
        documentFormData.append('address', this.address);
      }
      if (null != this.ibanPe && '' !== this.ibanPe) {
        documentFormData.append('ibanPE', this.ibanPe);
      }
      if (null != this.ibanQrf && '' !== this.ibanQrf) {
        documentFormData.append('ibanQRF', this.ibanQrf);
      }
      documentFormData.append('taxDeclarationNo', this.rsinFiscal);
      if (undefined !== agreeFile && null !== agreeFile) {
        documentFormData.append('issuerAgreementVO.agreementForm', agreeFile);
        if (agreeFile.name !== undefined) {
          documentFormData.append(
            'issuerAgreementVO.agreementFormName',
            agreeFile.name
          );
        }
        var startDate = new Date(this.startDate);
        var endDate = new Date(this.endDate);
        documentFormData.append(
          'issuerAgreementVO.effectiveEndDate',
          $filter('date')(endDate, 'yyyy-MM-dd HH:mm:ss')
        );
        documentFormData.append(
          'issuerAgreementVO.effectiveStartDate',
          $filter('date')(startDate, 'yyyy-MM-dd HH:mm:ss')
        );
      }
      fileUploadSvc
        .uploadFileToUrl(null, uploadUrl, documentFormData)
        .then(successCallback, errorCallback);
    };
    return DwtIssuerGroup;
  },
]);
