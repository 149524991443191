angular.module('userMaintenanceDwtModule')
  .factory(
    'DwtUser',
    [
      'userService',
      function(userService) {
        function DwtUser(userData, userType, groupId, userId,
          productType) {
          if (undefined !== userData) {
            this.title = userData.title;
            this.firstName = userData.firstName;
            this.lastName = userData.lastName;
            this.personalPhone = userData.personalPhoneNo;
            this.workPhone = userData.officePhoneNo;
            this.emailAddress = userData.email;
            this.userType = userType;
            this.userGroupId = groupId;
            this.prodType = productType;
            this.defaultLanguage = 'EN';
            this.status = userData.status;
            this.userId = userData.userId;
            this.loggedInUserId = userId;
            this.blockGroupEmail = userData.groupMail;
            if (userData.admin === 'Y') {
              this.isAdmin = 'Y';
            } else if (userData.admin === 'R') {
              this.isAdmin = 'R';
            } else {
              this.isAdmin = 'N';
            }
          } else {
            this.title = '';
            this.firstName = '';
            this.lastName = '';
            this.personalPhone = '';
            this.workPhone = '';
            this.emailAddress = '';
            this.userType = 'AG';
            this.userGroupId = '1';
            this.prodType = productType;
            this.defaultLanguage = 'EN';
            this.status = 'P';
            this.loggedInUserId = userId;
            this.blockGroupEmail = 'N';
            this.isAdmin = 'N';
          }
        }

        DwtUser.prototype.updateUser = function(successCallback,
          errorCallback) {
          userService.editUser(this.userId, this, 'secureToken',
            successCallback, errorCallback);
        };

        DwtUser.prototype.createUser = function(successCallback,
          errorCallback) {
          userService.createNewUser(this, 'secureToken').then(
            successCallback, errorCallback);
        };

        return DwtUser;
      }]);