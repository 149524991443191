angular.module('dwtModule').factory('ViewTaxReclaimModal', [
  'ViewDwtDetailService',
  function (ViewDwtDetailService) {
    function ViewTaxReclaimModal(data, objFlag) {
      this.returnObj = {};
      if (objFlag === 'reclaim') {
        this.tableData = [];
        angular.forEach(data, function (reclaimData) {
          this.tableData.push({
            type: reclaimData.type,
            totalSubmittedIssuer: reclaimData.totalSubmittedIssuer,
            paymentRequested: reclaimData.paymentRequested,
            paymentRecieved: reclaimData.paymentRecieved,
            paymentCompletedIntermediary:
              reclaimData.paymentCompletedIntermediary,
            totalClaimedAmount: reclaimData.totalClaimedAmount,
          });
        });
      } else if (objFlag === 'payment') {
        this.tableData = [];
        angular.forEach(data, function (paymentData) {
          this.tableData.push({
            paymentRequestDate: paymentData.paymentRequestDate,
            issuerPaymentReference: paymentData.issuerPaymentReference,
            totalAmount: paymentData.totalAmount,
            invoice: paymentData.invoice,
            paymentStatus: paymentData.paymentStatus,
            action: paymentData.action,
            intermediaryName: paymentData.intermediaryName,
            intermediaryPaymentReference:
              paymentData.intermediaryPaymentReference,
            intermediaryPayAmount: paymentData.intermediaryPayAmount,
            intermediaryIBAN: paymentData.intermediaryIBAN,
          });
        });
      }
    }
    ViewTaxReclaimModal.prototype.getPaymentDetailsOverview = function (
      successCallback,
      errorCallback
    ) {
      ViewDwtDetailService.getPaymentDetailsOverview(
        successCallback,
        errorCallback
      );
    };
    return ViewTaxReclaimModal;
  },
]);
