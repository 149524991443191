/**
 * @ngdoc controller
 * @name dwtModule
 * @description This is a modal object creation used for create and edit tax reclaim events of dwt module.
 * @requires $sessionStorage to store/pass data in session
 * @requires dwtEvent function to load the modal object with service data for create and edit dwt events
 * @requires createEditDwtService
 */
angular.module('dwtModule').factory('dwtEvent', [
  '$sessionStorage',
  'createEditDwtService',
  '$filter',
  function ($sessionStorage, createEditDwtService, $filter) {
    function dwtEvent(data) {
      if (undefined !== data.dwtEvent) {
        this.loggedInUserId = $sessionStorage.userId;
        this.eventId = data.dwtEvent.eventId;
        this.issuerId = data.dwtEvent.issuerId;
        this.isBinder = data.dwtEvent.isBinder;
        this.commentText = data.dwtEvent.commentText;
        this.shareClassName = data.dwtEvent.shareclassName;
        this.shareclassId = data.dwtEvent.shareclassId;
        this.peReclaim = data.dwtEvent.peReclaim;
        this.directDebitQrf = data.dwtEvent.directDebitQrf;
        this.directDebitPe = data.dwtEvent.directDebitPe;
        this.ibanQrf = data.dwtEvent.ibanQrf;
        this.ibanPe = data.dwtEvent.ibanPe;
        this.isin = data.dwtEvent.isin;
        this.rsin = data.dwtEvent.rsin;
        if (data.dwtEvent.dividendRecordDate === '01 Jan 1970') {
          this.dividendRecordDate = '';
        } else {
          this.dividendRecordDate = new Date(data.dwtEvent.dividendRecordDate);
        }
        if (data.dwtEvent.dividendPaymentDate === '01 Jan 1970') {
          this.dividendPaymentDate = '';
        } else {
          this.dividendPaymentDate = new Date(
            data.dwtEvent.dividendPaymentDate
          );
        }
        if (data.dwtEvent.qrfStartDate === '01 Jan 1970') {
          this.qrfStartDate = '';
        } else {
          this.qrfStartDate = new Date(data.dwtEvent.qrfStartDate);
        }
        if (
          $filter('date')(new Date(data.dwtEvent.qrfEndDate), 'dd MMM yyyy') ===
          '01 Jan 1970'
        ) {
          this.qrfEndDate = '';
        } else {
          this.qrfEndDate = new Date(data.dwtEvent.qrfEndDate);
        }
        if (data.dwtEvent.peStartDate === '01 Jan 1970') {
          this.peStartDate = '';
        } else {
          this.peStartDate = new Date(data.dwtEvent.peStartDate);
        }
        if (
          $filter('date')(new Date(data.dwtEvent.peEndDate), 'dd MMM yyyy') ===
          '01 Jan 1970'
        ) {
          this.peEndDate = '';
        } else {
          this.peEndDate = new Date(data.dwtEvent.peEndDate);
        }
        if (data.dwtEvent.taSubmissionDate === '01 Jan 1970') {
          this.taSubmissionDate = '';
        } else {
          this.taSubmissionDate = new Date(data.dwtEvent.taSubmissionDate);
        }
        this.tdNumber = data.dwtEvent.tdNumber;
        if (data.dwtEvent.dwtPerSecurity === 0) {
          this.dwtPerSecurity = '';
          this.nwt = '';
        } else {
          this.dwtPerSecurity = data.dwtEvent.dwtPerSecurity;
          this.nwt = data.dwtEvent.dwtPerSecurity;
        }
        if (data.dwtEvent.dwtPerSecurityStr === 0) {
          this.dwtPerSecurityStr = '';
          this.nwt = '';
        } else {
          this.dwtPerSecurityStr = data.dwtEvent.dwtPerSecurityStr;
          this.nwt = data.dwtEvent.dwtPerSecurityStr;
        }
        if (data.dwtEvent.dividendGross === 0) {
          this.dividendGross = '';
        } else {
          this.dividendGross = data.dwtEvent.dividendGross;
        }
        if (data.dwtEvent.dividendGrossStr === 0) {
          this.dividendGrossStr = '';
        } else {
          this.dividendGrossStr = data.dwtEvent.dividendGrossStr;
        }
        if (data.dwtEvent.dividendNet === 0) {
          this.dividendNet = '';
        } else {
          this.dividendNet = data.dwtEvent.dividendNet;
        }
        if (data.dwtEvent.dividendNetStr === 0) {
          this.dividendNetStr = '';
        } else {
          this.dividendNetStr = data.dwtEvent.dividendNetStr;
        }
        this.dwtPercent = data.dwtEvent.dwtPercent;
        this.status = data.dwtEvent.status;
        this.tempStatus = data.dwtEvent.tempStatus;
        this.newISIN = '';
        this.newSecurityClassName = '';
        this.eventName = data.dwtEvent.eventName.trim();
      } else if (
        undefined !== $sessionStorage.createData &&
        null !== $sessionStorage.createData
      ) {
        this.loggedInUserId = $sessionStorage.userId;
        this.eventId = $sessionStorage.createData.eventId;
        this.eventName = $sessionStorage.createData.eventName.trim();
        this.currency = $sessionStorage.createData.currency;
        this.dividendType = $sessionStorage.createData.dividendType;
        this.issuerName = $sessionStorage.createData.issuerName;
        this.commentText = $sessionStorage.createData.commentText;
        this.isin = $sessionStorage.createData.isin;
        this.issuerId = $sessionStorage.createData.issuerId;
        this.isBinder = $sessionStorage.createData.isBinder;
        this.shareClassName = $sessionStorage.createData.shareClassName;
        this.shareclassId = $sessionStorage.createData.shareclassId;
        this.peReclaim = $sessionStorage.createData.peReclaim;
        this.directDebitQrf = $sessionStorage.createData.directDebitQrf;
        this.directDebitPe = $sessionStorage.createData.directDebitPe;
        this.ibanQrf = $sessionStorage.createData.ibanQrf;
        this.ibanPe = $sessionStorage.createData.ibanPe;
        this.dividendRecordDate = new Date(
          $sessionStorage.createData.dividendRecordDate
        );
        this.dividendPaymentDate = new Date(
          $sessionStorage.createData.dividendPaymentDate
        );
        this.qrfStartDate = new Date($sessionStorage.createData.qrfStartDate);
        this.qrfEndDate = new Date($sessionStorage.createData.qrfEndDate);
        this.peStartDate = new Date($sessionStorage.createData.peStartDate);
        this.peEndDate = new Date($sessionStorage.createData.peEndDate);
        this.tdNumber = $sessionStorage.createData.tdNumber;
        this.taSubmissionDate = new Date(
          $sessionStorage.createData.taSubmissionDate
        );
        this.dwtPerSecurity = $sessionStorage.createData.dwtPerSecurity;
        this.dwtPerSecurityStr = $sessionStorage.createData.dwtPerSecurityStr;
        this.dividendGross = $sessionStorage.createData.dividendGross;
        this.dividendGrossStr = $sessionStorage.createData.dividendGrossStr;
        this.dividendNet = $sessionStorage.createData.dividendNet;
        this.dividendNetStr = $sessionStorage.createData.dividendNetStr;
        this.dwtPercent = $sessionStorage.createData.dwtPercent;
        this.status = $sessionStorage.createData.status;
        this.tempStatus = $sessionStorage.createData.tempStatus;
        this.nwt = $sessionStorage.createData.nwt;
        this.rsin = $sessionStorage.createData.rsin;
        this.status = $sessionStorage.createData.status;
        this.newISIN = $sessionStorage.createData.newISIN;
        this.newSecurityClassName =
          $sessionStorage.createData.newSecurityClassName;
      } else {
        this.loggedInUserId = $sessionStorage.userId;
        this.eventId = '';
        this.eventName = '';
        this.currency = '';
        this.dividendType = '';
        this.peReclaim = true;
        this.directDebitQrf = false;
        this.directDebitPe = false;
        this.issuerId = '';
        this.isBinder = '';
        this.isin = null;
        this.rsin = '';
        this.shareClassName = '';
        this.shareclassId = '';
        this.ibanQrf = '';
        this.ibanPe = '';
        this.dividendRecordDate = '';
        this.dividendPaymentDate = '';
        this.qrfStartDate = '';
        this.qrfEndDate = '';
        this.peStartDate = '';
        this.peEndDate = '';
        this.tdNumber = '';
        this.taSubmissionDate = '';
        this.dwtPerSecurity = '';
        this.dwtPerSecurityStr = '';
        this.dividendGross = '';
        this.dividendGrossStr = '';
        this.dividendNet = '';
        this.dividendNetStr = '';
        this.dwtPercent = 15;
        this.nwt = '';
        this.status = 'Draft';
        this.tempStatus = '';
        this.newISIN = '';
        this.newSecurityClassName = '';
      }
    }

    dwtEvent.prototype.createDwtEvent = function (
      successCallback,
      errorCallback
    ) {
      createEditDwtService
        .createDwt(this, 'secureToken')
        .then(successCallback, errorCallback);
    };

    return dwtEvent;
  },
]);
